<template>
    <scrim :active="active" :mode="mode">
        <MDText v-if="!!label" :label="label" scale="subtitle"/>
        <MDSpinner/>
    </scrim>
</template>

<script>
import { computed } from 'vue';
import MDText from '@/components/MDText.vue';
import MDSpinner from '@/components/MDSpinner.vue';

export default {
    name: "MDBlocker",
    components: {MDSpinner,MDText},
    props: {
        label: {
            type: String,
            default() {
                return null;
            }
        },
        mode: {
            type: String,
            default() {
                return 'absolute'
            }
        },
        active: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    setup( props ) {
        const active = computed(() => !!props.active)

        const mode = computed(() => props.mode)

        return {
            active,
            mode
        }
    }
}
</script>
