<template>
    <dialog gallery v-if="active()" ref="dialog" :uuid="props?.config.id">
        <dialogheading class="dialog-heading"><MDText v-if="!!label || props?.config.label" :label="label ?? props?.config.label" scale="subtitle1"/></dialogheading>
        <dialogcontent class="dialog-content" v-html="(content ?? props.config?.content)?.outerHTML" />
        <button class="dialog-close" @click="close"><icon class="md-icon">close</icon></button>
    </dialog>
</template>

<script>
import { ref, computed } from 'vue'
import MDText from '@/components/MDText.vue';

export default {
    name: "MDGalleryDialog",
    components: {MDText},
    props: {
        label: {
            type: String,
            default() {
                return null;
            }
        },
        content: {
            type: HTMLElement,
            default() {
                return null;
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup( props ) {
        const dialog = ref(null)
        const active = computed(() => {
            return () => {
                if(!!props.content) {
                    dialog.value?.showModal()
                }else{
                    dialog.value?.close()
                }
                return props.content ?? props?.config?.content ?? null
            }
        })

        const close = (e) => {
            dialog.value?.close()
        }

        return {
            active,
            dialog,
            props,
            close
        }
    }
}
</script>
