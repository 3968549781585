import axios from 'axios';
import { useStore } from '@/javascript/lib/store'
import { setupCache, buildWebStorage } from 'axios-cache-interceptor'

// axios default headers
// axios.defaults.defaults.headers.common['Authorization'] = `Bearer ${config.token}`;
const environments = ['local', 'production']
/**
 * Note: The current API version is sending no-cache headers, so any caching attempts
 * on the client-side will fail, even with axios-cache-intercept
 * @link https://stackoverflow.com/a/78008929
 * @link https://axios-cache-interceptor.js.org/config/request-specifics#cache-interpretheader
 * @link https://axios-cache-interceptor.js.org/config/request-specifics#cache-cachetakeover
 */
export const axioscacheconfig =  (() => {
    const o = {
        cache: {
            interpretHeader:false,
            cacheTakeover: true
        }
    }
    // Only providing caching configuration on production, dev and staging are disabled
    const h = document.querySelector('meta[name="x-environment"]')
    return (h instanceof HTMLElement && environments.includes(h?.getAttribute('content'))) ? o : {cache: false}
})()

export const axiosconfig =  {
    debug: console.log,
    storage: buildWebStorage(localStorage, 'rs-twa:'),
    methods: ['get', 'post']
}
axios.defaults.headers.common['Content-Type']  = 'application/json';
if (environments.includes(useStore.mode) || environments.includes(__umbrastate__.mode)) {
    axios.defaults.headers.common['Authorization'] = `Basic ${useStore.token ?? __umbrastate__.token}`;
}
// axios.defaults.headers.common['Cache-Control'] = 'no-cache';
// axios.defaults.headers.common['Pragma'] = 'no-cache';
let api = null
export const getAxiosInstance = () => {
    if(api == null) {
        api = setupCache(axios, axiosconfig)
    }
    return api
}
