<template>
    <rs-griditem ref="root" tile :="tileprops" :rs-index="index" :content="template" :title="link?.title" @click.prevent="clickhandler">
        <rs-wrapper easing>
            <template v-if="showtitle || showcontent || showlinks">
                <panel content>
                    <panel-inner>
                        <rs-heading  v-if="showtitle">
                            <h3 v-if="link?.url" :casing="casing" gutters="0" caps><a :href="link?.url" :title="link?.title" v-html="wrappedtitle"></a></h3>
                            <h3 v-else :casing="casing" gutters="0" caps v-html="wrappedtitle"></h3>
                        </rs-heading>
                        <rs-content v-if="showcontent" scale="body-sm" gutters="0" v-html="data?.content"></rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index"  :label="item?.title || 'Learn More'" :type="index == 0 ? 'contained' : 'outlined'" :class="item?.css" contained maxed solid />
                        </rs-actions>
                    </panel-inner>
                </panel>
            </template>
            <template v-if="image?.id">
                <panel media stackable :="panelprops" rounded radius-sm>
                    <panel-inner stackable-item>
                        <rs-heading  v-if="showtitle">
                            <h3 v-if="link?.url" :casing="casing" gutters="0" caps><a :href="link?.url" :title="link?.title" v-html="wrappedtitle"></a></h3>
                            <h3 v-else :casing="casing" gutters="0" caps v-html="wrappedtitle"></h3>
                        </rs-heading>
                    </panel-inner>
                    <Picture :config="image" :alt="image?.alt ?? data?.title" stackable-item />
                </panel>
            </template>
        </rs-wrapper>
    </rs-griditem>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString, useNormalizeLayoutProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'

export default {
    name: "CTATile",
    components: {Picture, MDButton},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const data          = ref(null)
        const image         = ref(null)
        const link          = ref(null)
        const layoutprops   = ref(null)
        const tileprops     = ref(null)
        const panelprops     = ref(null)
        const contentprops  = ref(null)
        const tileratios    = ref(null)
        const showcontent   = computed(() => !!data.value?.display?.displaycontent && data.value?.content)
        const showlinks     = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle     = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const casing        = ref(null)

        const template      = computed(() => data.value?.layout?.template == 'tile_content' ? 1 : null)
        const wrappedtitle  = computed(() => {
            return data.value?.title ? useWrappedString(data.value.title, [...data.value?.focustext ?? []]) : null
        })

        const clickhandler = (e) => {
            window.location = link.value?.url ?? window.location
        }

        watch(data, (n, o) => {
            if (n) {
                const {layout} = useNormalizeLayoutProps(data)
                layoutprops.value = {...layout.value}

                const {ratios} = useNormalizeRatioProps(data)
                tileratios.value = {...ratios.value ?? {}}

                // Build an grid attribute list and merge with tileratios
                tileprops.value = tileprops.value ?? {}
                data.value?.grid?.span?.map( v => {
                    tileprops.value[v] = ''
                })

                contentprops.value  = contentprops.value ?? {}
                data.value?.layout?.contentprops?.map( v => {
                    contentprops.value[v.key] = v.value
                })

                // Heading Casing
                casing.value = (contentprops.value?.template !== 'tile') ? 'display-mm' : 'display-xs'

                // Link
                link.value = [...data.value?.links]?.shift()

                // Background image
                image.value = {...data.value?.image}

                tileprops.value = {
                    ...layoutprops.value ?? {},
                    ...contentprops.value ?? {},
                    ...tileprops.value ?? {},
                    ...tileratios.value ?? {},
                }

                if (!!image.value?.id) {
                    tileprops.value = {
                        ...tileprops.value,
                        media: ''
                    }
                }

                if (link.value?.url) {
                    tileprops.value = {
                        ...tileprops.value,
                        linked: ''
                    }
                }

                // Background color replacement
                if (!!data.value?.display?.backgroundcolor) {
                    const c = data.value?.display?.backgroundcolor
                    panelprops.value = {
                        style: `background-color: ${c};`
                    }
                }
            }
        })

        onBeforeMount(() => {
            if((props.config?.constructor !== Object)) {
                data.value = JSON.parse(props.config)?.props
            }else{
                data.value = props.config?.props
            }
        })
        return {
            root,
            data,
            showcontent,
            showlinks,
            showtitle,
            tileprops,
            panelprops,
            layoutprops,
            wrappedtitle,
            casing,
            image,
            link,
            clickhandler,
            template
        }
    }
}
</script>
