<script>
import { ref, watch, computed, nextTick, createApp, toRaw, isProxy } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { getQuery } from '@/javascript/lib/queries'
import { uuid } from '@/javascript/lib/utils'
import { all } from 'axios'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import Mutator from '@/javascript/lib/mutator'
import MDDialog from '@/components/MDDialog.ce.vue'
import MDGalleryDialog from '@/components/MDGalleryDialog.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import Detail from '@/components/Detail.vue';

export default {
    name: "App",
    components: {MDDialog, MDBlocker, Detail, MDGalleryDialog},
    props: {
        config: {
            type: String,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const context           = ref(null)
        const store             = useStore()
        const api               = getAxiosInstance()
        const endpoint          = computed(() => (['local', 'localhost'].includes(store.mode)) ? 'https://dev.discoversurreybc.com/graphql' : '/graphql')
        const threshold         = [0, 0.25, 0, 0.75, 1]
        const formteplates      = ref(new Map())
        const allowedforms      = ref([]);
        const diagloginstance   = ref(null)
        const captchatoken      = ref(null)
        const isValid           = ref(true)
        const observer = new IntersectionObserver((entries) => {
            entries.map((entry) => {
                window.requestAnimationFrame(() => {
                    entry.target.toggleAttribute('in-viewport', entry.isIntersecting)
                })
            })
        }, {threshold : threshold})
        store.observer = observer
        context.value = window

        watch(isValid, (o, n) => {
            if (!!n) {
                console.log(isValid.value)
            }
        })

        // ------------------------------------------------------------------------------------------ //
        // FORM HANDLERS
        // ------------------------------------------------------------------------------------------ //
        const formSetup = (f) => {
            if (f instanceof HTMLFormElement) {
                const required = [...f.querySelectorAll('.required')]
                if (required?.length) {
                    f.removeAttribute('novalidate')
                    required?.map(r => r.setAttribute('required', 'required'))
                }

                f.addEventListener('submit', (e) => {
                    e.preventDefault()
                    e.stopImmediatePropagation()

                    // Basic validation
                    isValid.value = true
                    const emailConfirm = f.querySelector('input[type="email"][mustmatch]')
                    if (emailConfirm instanceof HTMLInputElement) {
                        const fieldname     = emailConfirm?.getAttribute('mustmatch')
                        const matchingField = f.querySelector(`input[type="email"][name="${fieldname}"]`)

                        if (matchingField instanceof HTMLInputElement) {
                            isValid.value = !!(emailConfirm.value == matchingField.value)
                            if(!isValid.value) {
                                isValid.value = false
                                alert('Please ensure your emails match.')
                                emailConfirm.focus({focusVisible: true})
                                return false
                            }
                        }
                    }

                    if( !!isValid ) {
                        // Handle recaptcha
                        if(('grecaptcha' in window) && store.site_key) {
                            grecaptcha.ready( () => {
                                grecaptcha.execute(store.site_key, {action: `submit`}).then( (token) => {
                                    captchatoken.value = token

                                    // Handle recaptcha token injection
                                    const captchafield = f.querySelector('input[name="g-recaptcha-response"]')
                                    if (captchafield instanceof HTMLInputElement) {
                                        captchafield.value = captchatoken.value
                                    }
                                    submit(f)
                                })
                            })
                        } else {
                            submit(f)
                        }
                    }
                })
            }
        }

        const submit = (form) => {
            if (form instanceof HTMLFormElement) {
                const data      = new FormData(form)
                const payload   = {...Object.fromEntries(data) ?? {}}
                const endpoint  = form.getAttribute('action')
                const headers   = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }

                // Block user input
                toggleBlocker(diagloginstance.value, true)

                api.post(endpoint, payload, headers).then( response => {
                    if ([200, 201].includes(response.status)) {
                        setResponsMessage(diagloginstance.value, response)
                        toggleBlocker(diagloginstance.value, false)
                    }
                }).catch(error => {
                    console.log(error, error.response)
                    toggleBlocker(diagloginstance.value, false)
                })
            }
        }

        const setResponsMessage = (element, response) => {
            if (element instanceof HTMLDialogElement) {
                const data = {
                    content: null,
                    title: null,
                    ...response?.data?.data ?? {}
                }

                const dialogcontent = element?.querySelector('dialogcontent')
                if (dialogcontent instanceof HTMLElement) {
                    dialogcontent.setAttribute('contentonly', '')
                    dialogcontent.innerHTML = data?.content?.content ?? ''
                }
            }

        }

        const m = new Mutator({
            selector: 'form',
            callback: (e) => {
                e.forEach((v) => {
                    formSetup(v)
                })
            }
        })

        /**
         * Collect all of the form templates that are available on the page,
         * and index them into a Map instance for quick lookup.
         */
        const t = new Mutator({
            selector: 'template.modalformtemplate',
            callback: (e) => {
                e.forEach((el) => {
                    const id = el instanceof HTMLTemplateElement ? el.getAttribute('id') : null

                    // Capture a list of allowed forms from the site config
                    if (isProxy(store?.gtm_events) && (!Object.keys(allowedforms.value)?.length)) {
                        allowedforms.value = [
                            ...Object.keys(store?.gtm_events)
                        ]
                    }

                    const form = el instanceof HTMLTemplateElement ? el?.content?.querySelector('form') : null

                    // Store an instance of the form for faster lookups later
                    if (form instanceof HTMLFormElement) {
                        formteplates.value.set(id, form)
                    }
                })
            }
        })

        // ------------------------------------------------------------------------------------------ //
        // UTILITY
        // ------------------------------------------------------------------------------------------ //
        const getBlockerInstance = (config = {}) => {
            const template  = document.createElement('template')
            const cfg = {
                active: true,
                mode: 'absolute',
                ...config
            }

            let instance = createApp(MDBlocker, {config: cfg}).mount(document.createElement('template'))
            template.innerHTML = instance.$el.cloneNode(true)?.outerHTML
            const blocker = template.content.querySelector('scrim')
            blocker.removeAttribute('config')
            // blocker.setAttribute('mode', 'absolute')
            // console.log('getBlockerInstance', blocker);
            return blocker

        }

        const toggleBlocker = (element, state = true) => {
            if (element instanceof HTMLElement) {
                const blocker = (element.querySelector('scrim') instanceof HTMLElement) ? element.querySelector('scrim') : getBlockerInstance({active:true})

                // Append the blocker to the host element if it doesn't exist
                if (!!blocker && !(element.querySelector('scrim') instanceof HTMLElement)) {
                    element.append(blocker)
                }

                if (!!state) {
                    blocker?.setAttribute('active', '')
                } else {
                    blocker?.remove()
                }
            }
        }

        // ------------------------------------------------------------------------------------------ //
        // CLICK HANDLERS
        // ------------------------------------------------------------------------------------------ //
        /**
         * Get a instance of a modal dialog
         *
         * @param config object A configuration object containing "type" and "selector" properties
         */
        const getModalDialogInstance = (config = {}) => {
            const template  = document.createElement('template')
            const cfg = {
                id: uuid(),
                title: 'Discover Surrey, BC',
                ...config ?? {}
            }

            const instance = createApp(MDDialog, {config: cfg}).mount(document.createElement('template'))?.$el.cloneNode(true)
            const dlgcontent = instance?.querySelector('dialogcontent')

            // Handle modal forms
            if (config?.type == 'modalform') {
                const form = formteplates.value?.get(config?.selector)?.cloneNode(true)
                if (dlgcontent && form) {
                    dlgcontent.appendChild(form)
                }
            }

            if (config?.type == 'modalpartner') {
                if (!!cfg?.html && dlgcontent) {
                    dlgcontent.appendChild(cfg?.html)
                }

            }

            template.innerHTML = instance.cloneNode(true).outerHTML

            return template.content.querySelector('dialog')
        }

        // ------------------------------------------------------------------------------------------ //
        // GALLERY IMAGE
        // ------------------------------------------------------------------------------------------ //
        watch(() => store.gallerystate.instance, (n, o) => {
            if (store.gallerystate.instance instanceof HTMLDialogElement) {
                store.gallerystate.instance?.setAttribute('gallery', '')
                store.gallerystate.instance?.addEventListener('close', (e) => {
                    e.target?.remove()
                })
                document.body.append(store.gallerystate.instance)
                store.gallerystate.instance?.showModal()
            }
        }, {
            deep: true
        })

        const displayGalleryItem = (asset) => {
            store.gallerystate.content = asset
            if (store.gallerystate.content || asset) {
                const template  = document.createElement('template')
                const cfg = {
                    id: store.gallerystate.id,
                    content: store.gallerystate?.content,
                    label: store.gallerystate?.label,
                }

                const instance      = createApp(MDGalleryDialog, {config: cfg}).mount(document.createElement('template'))?.$el.cloneNode(true)
                const dlgcontent    = instance?.querySelector('dialogcontent')
                const img           = Object.assign(document.createElement('img'), {
                    src: store?.gallerystate?.content
                })
                dlgcontent.appendChild(img)
                template.innerHTML = instance?.outerHTML

                store.gallerystate.label = 'Gallery'
                store.gallerystate.id = uuid() // important to ensure that Vue sees each instance/click as unique
                store.gallerystate.instance?.remove()
                store.gallerystate.instance = template.content.querySelector('dialog')
            }
        }

        /**
         * Show an instance of a form.
         *
         * @param config object An object literal containing "type" and "selector" properties
         */
        const showModalForm = (config = {}) =>  {
            resetDialogs()
            store.modaforminstance = getModalDialogInstance({...config, type: 'modalform'})
            if (store.modaforminstance instanceof HTMLDialogElement) {
                diagloginstance.value = store.modaforminstance.cloneNode(true)
                diagloginstance.value?.setAttribute(config?.selector, '')
                diagloginstance.value?.addEventListener('close', (e) => {
                    resetDialogs()
                    e.target?.remove()
                })
                document.body.append(diagloginstance.value)
                diagloginstance.value?.showModal()
            }
        }

        context.value?.addEventListener('click', (e) => {
            const dom       = [...e?.composedPath() ?? []]
            const el        = dom.shift()
            const href      = el instanceof HTMLAnchorElement ? el?.getAttribute('href')?.split('#')?.pop() : null
            const validform = allowedforms.value?.includes(href)

            // Handle modal form links
            if (el instanceof HTMLElement && (validform ||
                    el.matches('.dialog-close') ||
                    el.matches('[partner-link]') ||
                    el.matches('.view-gallery--item'))) {
                e.preventDefault()
                e.stopImmediatePropagation()
            }

            if (validform) {
                showModalForm({type: 'modalform', selector: href, type: 'modalform'})
            }

            // Handle modal dialog close buttons
            if (el?.matches('.dialog-close')) {
                const dlg = el.closest('dialog')
                if (dlg instanceof HTMLDialogElement && !(dlg.matches(['gallery']))) {
                    dlg?.remove()
                }

                resetDialogs()
            }

            // Handle partner links
            if (el?.matches('[partner-link]')) {
                store.modalstate.selection = parseInt(el.getAttribute('partner-link') ?? 0, 10)
            }

            // Handle gallery images
            if(el?.matches('.view-gallery--item')) {
                displayGalleryItem(el?.href)
            }
        })

        /**
         * Reset all dialog instances
         */
        const resetDialogs = () => {
            [...document.querySelectorAll('dialog:not([gallery])')]?.map( v => v?.remove() )
            store.modalstate.content = store.modalstate.loading = false
            store.dialoginstance = store.modaforminstance = store.modalstate.instance = store.modalstate.selection = null
            diagloginstance.value = store.gallerystate.content = store.gallerystate.label = null
        }

        // ------------------------------------------------------------------------------------------ //
        // PARTNER LINK HANDLER
        // ------------------------------------------------------------------------------------------ //
        watch(() => store.modalstate.selection, (n, o) => {
            if (!!n) {
                const query = getQuery('readFeature')({id:store.modalstate?.selection})
                if(!!store.modalstate?.selection && query) {
                    store.modalstate.loading = true
                    store.modalstate.content = null
                    api.post(endpoint.value, {...query}, axioscacheconfig).then( response => {
                        const data = store.modalstate.data = {
                            ...response.data?.data?.readFeature ?? {}
                        }

                        if (response.status == 200) {
                            store.modalstate.content = getPartnerModalContent(data)
                            showPartnerModal({
                                id: store.modalstate.selection,
                                data: data,
                                html: store.modalstate.content
                            })
                        }
                    }).catch(error => {
                        console.log(error, error.response)
                        state.loading = false
                    })
                }
            }
        })

        const getPartnerModalContent = (data) => {
            if (data instanceof Object) {
                const template  = document.createElement('template')
                let instance    = createApp(Detail, {config: data}).mount(document.createElement('template'))
                template.innerHTML = instance.$el?.outerHTML;
                instance = null
                const img = template.content.querySelector('img')
                if (!!data?.image?.url && !!img) {
                    img.setAttribute('srcset', data?.image?.url)
                    img.setAttribute('src', data?.image?.thumbnail)
                }
                const content = template.content.querySelector('.detailcard')
                return content
            }
            return null
        }

        /**
         * Show an instance of a form.
         *
         * @param config object An object literal containing "type" and "selector" properties
         */
         const showPartnerModal = (config = {}) =>  {
            resetDialogs()
            store.modalstate.instance = getModalDialogInstance({...config, type: 'modalpartner'})
            if (store.modalstate.instance instanceof HTMLDialogElement) {
                store.modalstate.instance = store.modalstate.instance.cloneNode(true)
                store.modalstate.instance?.setAttribute('modal', '')
                store.modalstate.instance?.addEventListener('close', (e) => {
                    resetDialogs()
                    e.target?.remove()
                })
                document.body.append(store.modalstate.instance)
                store.modalstate.instance?.showModal()
            }
        }

        // ------------------------------------------------------------------------------------------ //
        // SERPS
        // ------------------------------------------------------------------------------------------ //
        const tabpagecache  = new Map()
        const tabcache      = new Map()
        const serptabs = (e) => {
            const tabs = [...e?.querySelectorAll('tab')]
            tabs?.map((v) => {
                const key = v?.getAttribute('key')
                if (!tabcache.has(key)) {
                    tabcache.set(key, v)
                }
            })

            const firsttab = [...tabcache.values()]?.shift()
            const firstgroup = [...tabpagecache.values()].shift()
            window.requestAnimationFrame(() => {
                firsttab.setAttribute('selected', '')
                firstgroup.setAttribute('selected', '')
            })

            e?.addEventListener('click', (evt) => {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                const tab = evt.target;
                if (tab.nodeName.toLowerCase() == 'tab') {
                    const key = tab.getAttribute('key')
                    let target = null;
                    if (!!key) {
                        if (!tabpagecache.has(key)) {
                            target = document.querySelector(`serpgroup[key="${key}"]`)
                            tabpagecache.set(key, target)
                        } else {
                            target = tabpagecache.get(key)
                        }
                        resetTabState(tab, target)
                    }
                }
            })
        }

        const resetTabState = (tab, target) => {
            const tabs = [...tabcache.values()]
            const tabgroups = [...tabpagecache.values()]

            window.requestAnimationFrame(() => {
                tabs?.map( v => v.removeAttribute('selected') )
                tabgroups?.map( v => v.removeAttribute('selected') )

                // Set selected state
                tab.setAttribute('selected', '')
                target.setAttribute('selected', '')
            })

        }

        const tabgroups = new Mutator({
            selector: 'serpgroup',
            callback: (e) => {
                e.forEach((v) => {
                    const key = v.getAttribute('key')
                    if (!tabpagecache.has(key)) {
                        tabpagecache.set(key, v)
                    }
                })
            }
        })

        const tabsmutator = new Mutator({
            selector: 'serptabs',
            callback: (e) => {
                e.forEach((v) => {
                    serptabs(v)
                })
            }
        })

        return {
        }
    }
}
</script>
